import React from "react";
import "../styles/App.css";
import logo from "../img/logo.png";
import line from "../img/line.png";
import { ReactComponent as Main } from "../img/main.svg";
import gearDark from "../img/gear_dark.png";
import gearLight from "../img/gear_light.png";

function App() {
  return (
    <div className="main__container">
      <img src={logo} alt="Логотип" className="main__container-logo" />
      <h1 className="main__container-title">обновление сайта</h1>
      <p className="main__container-text">Скоро тут появится настоящее чудо!</p>
      <img src={line} className="main__container-line" />
      <p className="main__container-description">
        В данный момент сайт находится на техническом обслуживании. Пожалуйста,
        зайдите позже.
        <br />
        Для получения обратной связи напишите нам:
        <a className="main__container-email" href="mailto:info@efektiv.ru">
          {" "}
          info@efektiv.ru
        </a>{" "}
        По вопросам сотрудничества:
        <a className="main__container-phone" href="tel:+79102559775">
          {" "}
          8 (910) 255 97-75
        </a>
      </p>

      <div className="main__container-gear">
        <div className="main__container-gear-left">
          <img src={gearLight} className="main__container-gear-light" />
          <img src={gearDark} className="main__container-gear-dark" />
        </div>
        <Main className="main__container-main" />
        <div className="main__container-gear-right">
          <img src={gearDark} className="main__container-gear-dark" />
          <img src={gearLight} className="main__container-gear-light" />
        </div>
      </div>
    </div>
  );
}

export default App;
